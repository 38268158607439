<template>
  <section>
    <Loading v-if="estadoLoading" />
    <div v-else>
      <h2 class="text-center mt-5 mb-4 text-holistica">
        <strong>Ranking de {{ rankings.taller }}</strong>
      </h2>
      <p class="text-center mb-4" style="font-size: 1.3rem">
        Estos son los participantes que más sobresalieron en la actividad.
      </p>
      <div
        class="borde-bottom-top5 d-flex justify-content-center mt-5 pt-5"
        v-if="rankings"
      >
        <div
          class="d-inline-block w-25"
          v-for="(ranking, i) in rankings.info"
          :key="i"
          v-if="i < 5"
        >
          <div
            class="bandera d-flex justify-content-center position-relative row"
          >
            <div class="position-absolute estrella">
              <p class="m-0 posicion-absolute posicion">{{ i + 1 }}</p>
            </div>
            <div
              class="align-items-end d-flex col-lg-12 justify-content-center pt-4 mb-2"
            >
              <img
                src="@/assets/img/uploadFile.jpg"
                width="100"
                height="100"
                class="rounded-circle"
                style="border: 2px solid #001f4a"
                v-if="!ranking.foto"
              />
              <img
                v-else
                class="img-fluid rounded-circle"
                :src="url_foto + 'fotos/' + ranking.foto"
                width="100"
                height="100"
                style="border: 2px solid #001f4a; width: 100px; height: 100px"
                alt
              />
            </div>
            <div class="col-12 text-center">
              <p class="m-0 my-2 name-puntaje text-holistica">
                {{ ranking.nombre }}
              </p>
              <p class="m-0 name-puntaje text-holistica font-weight-bold">
                Puntaje {{ ranking.puntaje.toFixed(1) }} / 10
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr class="separador" />
      <div class="row m-0 p-0" v-if="rankings">
        <div class="col-lg-3">
          <ul class="m-0 p-0" style="list-style: none">
            <li
              v-for="(ranking, i) in rankings.info"
              :key="i"
              v-if="i >= 5 && i < 10"
            >
              <p class="m-0 my-2 name-puntaje text-holistica">
                {{ i + 1 }} {{ ranking.nombre }}
              </p>
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <ul class="m-0 p-0" style="list-style: none">
            <li
              v-for="(ranking, i) in rankings.info"
              :key="i"
              v-if="i >= 5 && i < 10"
            >
              <p class="m-0 my-2 name-puntaje text-holistica font-weight-bold">
                Puntaje {{ ranking.puntaje.toFixed(1) }} / 10
              </p>
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <ul class="m-0 p-0" style="list-style: none">
            <li v-for="(ranking, i) in rankings.info" :key="i" v-if="i >= 10">
              <p class="m-0 my-2 name-puntaje text-holistica">
                {{ i + 1 }} {{ ranking.nombre }}
              </p>
            </li>
          </ul>
        </div>
        <div class="col-lg-3">
          <ul class="m-0 p-0" style="list-style: none">
            <li v-for="(ranking, i) in rankings.info" :key="i" v-if="i >= 10">
              <p class="m-0 my-2 name-puntaje text-holistica font-weight-bold">
                Puntaje {{ ranking.puntaje.toFixed(1) }} / 10
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="row m-0 p-0 justify-content-center mb-3">
        <div
          class="align-items-end col-lg-4 d-flex justify-content-center align-items-center"
        >
          <img
            src="@/assets/img/logo-cerforc.svg"
            class="img-fluid"
            width="250"
            height="120"
          />
        </div>
        <div
          class="align-items-end col-lg-4 d-flex justify-content-center align-items-center"
        >
          <img
            src="@/assets/img/logo-holistica.svg"
            class="img-fluid"
            width="280"
            height="120"
          />
        </div>
        <div
          class="col-lg-4 d-flex justify-content-center align-items-center"
          v-if="rankings"
        >
          <img
            :src="url_foto + 'logo_empresas/' + rankings.logo_empresa"
            class="img-fluid"
            height="120"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Echo from "laravel-echo";
import { mapGetters } from "vuex";
import Loading from "@/components/Loading";
export default {
  name: "Ranking",
  data() {
    return {
      url_foto: process.env.VUE_APP_RUTA_IMG,
    };
  },
  components: {
    Loading,
  },
  async mounted() {
    window.Pushe = require("pusher-js");
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "f85d5ce5fbc4d49a94e5",
      cluster: "mt1",
      wsHost: "webserver.plataformaholisticaweb.com",
      wssHost: "webserver.plataformaholisticaweb.com",
      wsPort: 6001,
      wssPort: 6001,
      disableStats: true,
      forceTLS: true,
      enabledTransports: ["ws", "wss"],
    });
    await this.$store.dispatch("fetchRanking", this.$route.params.id);
    window.Echo.channel("ranking").listen("RankingEvent", (e) => {
      console.log(e);
      this.$store.commit("setRanking", e.ranking.original.DATA);
    });
  },
  computed: {
    ...mapGetters({
      rankings: "getRankings",
      estadoLoading: "getEstatusLoading",
    }),
  },
};
</script>
<style lang="css">
.borde-bottom-top5 {
  overflow: auto;
  white-space: nowrap;
}
/* width */
.borde-bottom-top5::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

/* Track */
.borde-bottom-top5::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.borde-bottom-top5::-webkit-scrollbar-thumb {
  background: #eeeeee;
  border-radius: 5px;
}

/* Handle on hover */
.borde-bottom-top5::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
.bandera {
  height: 400px;
  background-image: url("../../../assets/img/bandera.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.estrella {
  width: 200px;
  height: 200px;
  background-image: url("../../../assets/img/estrella.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 170px 170px;
  transform: translate(55%, -34%);
  right: 52%;
}
.posicion {
  font-size: 4.35rem;
  color: #fff;
  bottom: 25%;
  right: 40%;
  transform: translate(40%, 50%);
  -webkit-transform: translate(40%, 50%);
  -moz-transform: translate(40%, 50%);
  -o-transform: translate(40%, 50%);
}
.posicion p {
  font-size: 4.25rem;
  text-align: center;
}
.name-puntaje {
  font-size: 1.35rem;
}
.separador {
  border-top: 0;
  border-bottom: 3px dashed #022f3f;
  width: 95%;
}

.w-25 {
  width: 19% !important;
}
</style>