<template>
  <section>
    <div class="row m-0" style="overflow-y: hidden; overflow: hidden">
      <div
        class="col-lg-3 bg-user border-right shadow p-0"
        style="height: 100vh"
      >
        <div class="p-2 text-center pt-4">
          <img
            src="@/assets/img/logo-holistica.svg"
            alt
            srcset
            class="img-fluid"
          />
        </div>
        <div
          class="d-flex align-items-center flex-row"
          style="height: calc(100% - 250px)"
        >
          <div class="col-lg-12 row p-0 m-0">
            <div class="col-lg-12 p-0 d-flex justify-content-center">
              <img
                src="@/assets/img/uploadFile.jpg"
                width="250"
                height="250"
                class="rounded-circle"
                style="border: 2px solid #001f4a"
                v-if="!participante || !participante.foto"
              />
              <img
                v-else
                :src="`${url_photo}fotos/${participante.foto}`"
                width="250"
                height="250"
                class="rounded-circle"
                style="border: 2px solid #001f4a"
              />
            </div>
            <div class="col-lg-12 mt-5 p-0">
              <div class="row justify-content-center">
                <div class="col-lg-7" style="border-bottom: 1px solid #001f4a">
                  <p
                    class="text-center mb-1 font-weight-bold tipo-font"
                    style="color: #001f4a; font-size: 1.5rem"
                  >
                    {{ participante.nombre }} {{ participante.apellido }}
                  </p>
                </div>
                <div class="col-lg-7">
                  <p class="text-center pt-1 font-weight-bold tipo-font">
                    Nombre vocero
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center p-2 px-5" v-if="logo">
          <img
            :src="`${url_photo}logo_empresas/${logo}`"
            alt
            srcset
            height="120"
          />
        </div>
      </div>
      <div
        class="col-lg-9 pt-5 content-grafica"
        v-if="evaluar.metodologia == 0"
      >
        <h3 class="mb-0 pt-3 text-center font-weight-bold tipo-font">
          Comparativo de resultados
        </h3>
        <div class="">
          <div class="row">
            <div class="align-items-center col-4 d-flex justify-content-center">
              <div class="h-auto">
                <h4 class="tipo-font">
                  <strong>Cuerpo</strong>
                </h4>
                <div class="card shadow-sm">
                  <p
                    class="m-0 text-center m-2 tipo-font"
                    style="font-size: 1.75rem"
                  >
                    <strong>35 %</strong>
                  </p>
                </div>
              </div>
              <div class="position-absolute" style="right: 0; top: 40%">
                <p
                  class="mb-4 font-weight-bold tipo-font"
                  style="font-size: 1.25rem"
                >
                  Inicial
                </p>
                <p
                  class="font-weight-bold tipo-font"
                  style="font-size: 1.25rem"
                >
                  Final
                </p>
              </div>
            </div>
            <div class="col-8">
              <v-chart :options="bar" :autoresize="true" />
            </div>
            <div class="align-items-center col-4 d-flex justify-content-center">
              <div class="h-auto">
                <h4 class="tipo-font">
                  <strong>Voz</strong>
                </h4>
                <div class="card shadow-sm">
                  <p
                    class="m-0 text-center m-2 tipo-font"
                    style="font-size: 1.75rem"
                  >
                    <strong>15 %</strong>
                  </p>
                </div>
              </div>
              <div class="position-absolute" style="right: 0; top: 40%">
                <p
                  class="mb-4 font-weight-bold tipo-font"
                  style="font-size: 1.25rem"
                >
                  Inicial
                </p>
                <p
                  class="font-weight-bold tipo-font"
                  style="font-size: 1.25rem"
                >
                  Final
                </p>
              </div>
            </div>
            <div class="col-8">
              <v-chart :options="bar2" :autoresize="true" />
            </div>
            <div class="align-items-center col-4 d-flex justify-content-center">
              <div class="h-auto">
                <h4 class="tipo-font">
                  <strong>Mensaje</strong>
                </h4>
                <div class="card shadow-sm">
                  <p
                    class="m-0 text-center m-2 tipo-font"
                    style="font-size: 1.75rem"
                  >
                    <strong>50 %</strong>
                  </p>
                </div>
              </div>
              <div class="position-absolute" style="right: 0; top: 40%">
                <p
                  class="mb-4 font-weight-bold tipo-font"
                  style="font-size: 1.25rem"
                >
                  Inicial
                </p>
                <p
                  class="font-weight-bold tipo-font"
                  style="font-size: 1.25rem"
                >
                  Final
                </p>
              </div>
            </div>
            <div class="col-8">
              <v-chart :options="bar3" :autoresize="true" />
            </div>
          </div>
        </div>
        <div class="p-2 text-center mt-3">
          <div class="row">
            <div class="col-7 p-0" style="padding-left: 11rem !important">
              <div class="text-left" style="font-size: 1.5rem">
                <p class="mb-0">
                  El 100% de la evaluación se compone por la suma de tres
                  aspectos fundamentales:
                </p>
              </div>
              <div class="text-left" style="font-size: 1.5rem">
                <p class="mb-0">
                  <strong>1.</strong> 35% Comunicación no verbal / cuerpo
                  (expresión corporal).
                </p>
                <p class="mb-0">
                  <strong>2.</strong> 15% Comunicación no verbal / voz
                  (expresión vocal).
                </p>
                <p class="mb-0">
                  <strong>3.</strong> 50% Comunicación verbal / mensaje
                  (contenido del mensaje).
                </p>
              </div>
            </div>
            <div
              class="align-items-end col-5 d-flex justify-content-end p-0 pb-3 pr-5"
            >
              <img src="@/assets/img/logo-cerforc.svg" alt srcset height="60" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 pt-5 content-grafica" v-else>
        <h3 class="mb-0 pt-3 text-center tipo-font font-weight-bold">
          Comparativo de resultados
        </h3>
        <div
          class="mt-n4 d-flex justify-content-center align-items-center h-75"
        >
          <div class="row">
            <div
              class="col-lg-4 d-flex flex-column justify-content-around p-0 pt-5 text-right"
            >
              <div
                v-for="(aspecto, index) in evaluar.aspectos"
                :key="aspecto.id"
              >
                <p
                  class="font-weight-bold tipo-font"
                  style="font-size: 1.5rem"
                  :class="index == 0 ? 'mt-n2' : ''"
                >
                  {{ aspecto.nombre }}
                </p>
              </div>
            </div>
            <div class="col-lg-8">
              <v-chart
                :options="barMetodologiaNueva"
                autoresize
                style="width: 600px; height: 400px"
                ref="bar"
              />
            </div>
          </div>
        </div>
        <div class="p-2 text-center mt-3">
          <div class="row">
            <div class="col-7 p-0" style="padding-left: 11rem !important">
              <div class="text-left" style="font-size: 1.5rem">
                <p class="mb-0">
                  El 100% de <strong>{{ evaluar.tipo_taller }}</strong> se
                  compone por la suma de tres aspectos fundamentales:
                </p>
              </div>
              <div class="text-left" style="font-size: 1.5rem">
                <p
                  class="mb-0"
                  v-for="(m, index) in evaluar.aspectos"
                  :key="m.id"
                >
                  <strong>{{ index + 1 }}.</strong> {{ m.porcentaje }}%
                  {{ m.nombre }}
                </p>
              </div>
            </div>
            <div
              class="align-items-end col-5 d-flex justify-content-end p-0 pb-3 pr-5"
            >
              <img src="@/assets/img/logo-cerforc.svg" alt srcset height="60" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Echo from "laravel-echo";
import ECharts from "vue-echarts";
import echarts from "echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
export default {
  name: "grafica",
  data() {
    return {
      url_photo: process.env.VUE_APP_RUTA_IMG,
      participante: {},
      logo: "",
      guia: [],
      bar: {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.seriesName} <br> ${params.value} %`;
          },
        },
        grid: {
          left: "3%",
          right: "20%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          type: "category",
        },
        series: [
          {
            name: "Inicial",
            type: "bar",
            label: {
              normal: {
                position: "right",
                show: true,
                fontSize: 20,
                formatter: function (params) {
                  return `${params.value} %`;
                },
              },
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: [50, 50],
              },
              normal: {
                barBorderRadius: [0, 50, 50, 0],
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FFB300",
                  },
                  {
                    offset: 1,
                    color: "#FF6831",
                  },
                ]),
              },
            },
            data: [],
          },
          {
            name: "Final",
            type: "bar",
            label: {
              normal: {
                position: "right",
                show: true,
                fontSize: 20,
                formatter: function (params) {
                  return `${params.value} %`;
                },
              },
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: [50, 50],
              },
              normal: {
                barBorderRadius: [0, 50, 50, 0],
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#003D98",
                  },
                  {
                    offset: 1,
                    color: "#001F4A",
                  },
                ]),
              },
            },
            data: [],
          },
        ],
      },
      bar2: {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.seriesName} <br> ${params.value} %`;
          },
        },
        grid: {
          left: "3%",
          right: "20%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          type: "category",
        },
        series: [
          {
            name: "Inicial",
            type: "bar",
            label: {
              normal: {
                position: "right",
                show: true,
                fontSize: 20,
                formatter: function (params) {
                  return `${params.value} %`;
                },
              },
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: [50, 50],
              },
              normal: {
                barBorderRadius: [0, 50, 50, 0],
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FFB300",
                  },
                  {
                    offset: 1,
                    color: "#FF6831",
                  },
                ]),
              },
            },
            data: [],
          },
          {
            name: "Final",
            type: "bar",
            label: {
              normal: {
                position: "right",
                show: true,
                fontSize: 20,
                formatter: function (params) {
                  return `${params.value} %`;
                },
              },
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: [50, 50],
              },
              normal: {
                barBorderRadius: [0, 50, 50, 0],
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#003D98",
                  },
                  {
                    offset: 1,
                    color: "#001F4A",
                  },
                ]),
              },
            },
            data: [],
          },
        ],
      },
      bar3: {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.seriesName} <br> ${params.value} %`;
          },
        },
        grid: {
          left: "3%",
          right: "20%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          type: "category",
        },
        series: [
          {
            name: "Inicial",
            type: "bar",
            label: {
              normal: {
                position: "right",
                show: true,
                fontSize: 20,
                formatter: function (params) {
                  return `${params.value} %`;
                },
              },
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: [50, 50],
              },
              normal: {
                barBorderRadius: [0, 50, 50, 0],
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FFB300",
                  },
                  {
                    offset: 1,
                    color: "#FF6831",
                  },
                ]),
              },
            },
            data: [],
          },
          {
            name: "Final",
            type: "bar",
            label: {
              normal: {
                position: "right",
                show: true,
                fontSize: 20,
                formatter: function (params) {
                  return `${params.value} %`;
                },
              },
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: [50, 50],
              },
              normal: {
                barBorderRadius: [0, 50, 50, 0],
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#003D98",
                  },
                  {
                    offset: 1,
                    color: "#001F4A",
                  },
                ]),
              },
            },
            data: [],
          },
        ],
      },
      barMetodologiaNueva: {
        tooltip: {
          trigger: "item",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          type: "category",
          data: [],
          show: false,
        },
        series: [
          {
            type: "bar",
            label: {
              normal: {
                position: "right",
                show: true,
                formatter: function (params) {
                  return `${params.value} %`;
                },
                fontSize: 20,
              },
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: [50, 50],
              },
              normal: {
                barBorderRadius: [0, 50, 50, 0],
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#003D98",
                  },
                  {
                    offset: 1,
                    color: "#001F4A",
                  },
                ]),
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  components: {
    "v-chart": ECharts,
  },
  computed: {
    evaluar() {
      return this.$store.getters.getEvaluacion;
    },
    resultados() {
      return this.$store.getters.getResultados;
    },
  },
  watch: {
    participante: {
      deep: true,
      async handler() {
        const { STATUS_CODE } = await this.$store.dispatch(
          "fetchResultado",
          this.$route.params.id
        );
        if (STATUS_CODE == 1) {
          if (this.evaluar.metodologia == 0) {
            for (let index = 0; index < this.resultados.length; index++) {
              const element = this.resultados[index];
              this.chartValueForma(element);
            }
          } else {
            //this.initNameGrafica(this.evaluar);
            for (let index = 0; index < this.resultados.length; index++) {
              const element = this.resultados[index];
              this.setGrafica(element);
            }
          }
        }
      },
    },
    evaluar: {
      deep: true,
      handler(newData, oldData) {
        this.initNameGrafica(newData);
      },
    },
  },
  async mounted() {
    window.Pushe = require("pusher-js");
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "f85d5ce5fbc4d49a94e5",
      cluster: "mt1",
      wsHost: "webserver.plataformaholisticaweb.com",
      wssHost: "webserver.plataformaholisticaweb.com",
      wsPort: 6001,
      wssPort: 6001,
      disableStats: true,
      forceTLS: true,
      enabledTransports: ["ws", "wss"],
    });

    await this.$store.dispatch("fectEvaluacion", this.$route.params.id);
    await window.Echo.channel("participante").listen("NewParticipante", (e) => {
      this.participante = e.usuario;
      this.logo = e.logo;
      this.limpiar_grafica();
    });

    await window.Echo.channel("grafica").listen("NewGrafica", (e) => {
      this.resultados.push(e.grafica);
      if (this.evaluar.metodologia == 0) {
        this.chartValueForma(e.grafica);
      } else {
        this.setGrafica(e.grafica);
      }
    });
    await window.Echo.channel("actualizar").listen("UpdateGrafica", (e) => {
      this.$store
        .dispatch("fetchResultado", this.$route.params.id)
        .then((response) => {
          if (response.STATUS_CODE == 1) {
            if (this.evaluar.metodologia == 0) {
              this.chartValueForma(e.grafica);
            } else {
              this.setGrafica(e.grafica);
            }
          }
        });
    });
  },
  methods: {
    limpiar_grafica() {
      this.bar.series[0].data = [];
      this.bar.series[1].data = [];
      this.bar2.series[0].data = [];
      this.bar2.series[1].data = [];
      this.bar3.series[0].data = [];
      this.bar3.series[1].data = [];
    },

    initNameGrafica(info) {
      for (let index = info.aspectos.length - 1; index >= 0; index--) {
        const element = info.aspectos[index];
        this.barMetodologiaNueva.series[0].data.push(0);
        this.guia.push(element.nombre);
        if (index != 0) {
          this.barMetodologiaNueva.series[0].data.push("");
        }
      }
    },
    setGrafica(datos) {
      let key = undefined;
      let name = this.getName(datos.appearence_id);
      let labelGrafica = this.evaluar.aspectos;
      let copia = this.barMetodologiaNueva.series[0].data;
      for (let index = this.guia.length - 1; index >= 0; index--) {
        const element = this.guia[index];
        if (element === name) {
          if (index % 2 == 0) {
            key = index == 0 ? index : index + 2;
          } else {
            key = index + 1;
          }
        }
      }
      if (key != undefined) {
        copia[key] = this.getPuntaje(datos).toFixed(2);
        this.barMetodologiaNueva.series[0].data = [];
        this.barMetodologiaNueva.series[0].data = copia;
      }
    },

    getName(id) {
      let nombre = "";
      for (let index = 0; index < this.evaluar.aspectos.length; index++) {
        const element = this.evaluar.aspectos[index];
        if (element.id == id) {
          nombre = element.nombre;
        }
      }
      return nombre;
    },
    getPuntaje(info) {
      let porcentaje = 0;
      for (let index = 0; index < this.evaluar.aspectos.length; index++) {
        const element = this.evaluar.aspectos[index];
        if (element.id == info.appearence_id) {
          porcentaje = element.porcentaje;
        }
      }
      const porcentaje_optimo = (10 * porcentaje) / 100;
      const puntaje = (info.puntaje * porcentaje) / 100;
      return (puntaje * porcentaje) / porcentaje_optimo;
    },
    chartValueForma(grafica) {
      switch (grafica.subtipo) {
        case 1:
          if (grafica.fase == 1) {
            const result = this.puntaje(1, 1, 35);
            this.bar.series[0].data = [];
            this.bar.series[0].data.push(result.toFixed(2));
          } else {
            const result = this.puntaje(1, 2, 35);
            this.bar.series[1].data = [];
            this.bar.series[1].data.push(result.toFixed(2));
          }
          break;
        case 2:
          if (grafica.fase == 1) {
            const result = this.puntaje(2, 1, 15);
            this.bar2.series[0].data = [];
            this.bar2.series[0].data.push(result.toFixed(2));
          } else {
            const result = this.puntaje(2, 2, 15);
            this.bar2.series[1].data = [];
            this.bar2.series[1].data.push(result.toFixed(2));
          }
          break;
        case 3:
          if (grafica.fase == 1) {
            const result = this.puntaje(3, 1, 50);
            this.bar3.series[0].data = [];
            this.bar3.series[0].data.push(result.toFixed(2));
          } else {
            const result = this.puntaje(3, 2, 50);
            this.bar3.series[1].data = [];
            this.bar3.series[1].data.push(result.toFixed(2));
          }
          break;
        default:
          break;
      }
    },

    puntaje(subtipo, fase, porcentaje) {
      let suma = 0,
        total = 0;
      for (let index = 0; index < this.resultados.length; index++) {
        const element = this.resultados[index];
        if (
          element.subtipo == subtipo &&
          element.user_id == this.participante.id &&
          element.fase == fase
        ) {
          suma += element.puntaje;
        }
      }
      if (suma != 0) {
        total = (suma * porcentaje) / 100;
        console.log(
          (total * porcentaje) / this.puntajeOptimo(subtipo, porcentaje)
        );
        return (total * porcentaje) / this.puntajeOptimo(subtipo, porcentaje);
      }
    },
    puntajeOptimo(subtipo, porcentaje) {
      let suma = 0,
        resultado = 0;
      for (let index = 0; index < this.evaluar.aspectos.length; index++) {
        const element = this.evaluar.aspectos[index];
        if (element.subtipo == subtipo) {
          suma += element.subaspectos.length;
        }
      }
      resultado = (suma * 10 * porcentaje) / 100;
      return resultado.toFixed(2);
    },
  },
};
</script>
<style lang="css">
.content-grafica {
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}
.echarts {
  width: 600px;
  height: 200px;
}
.tipo-font {
  font-family: Arial, Helvetica, sans-serif !important;
}
.bg-user {
  background-color: #eeeeee;
}
</style>