<template>
  <div class="contenido-configuracion d-flex justify-content-center align-items-center">
    <div class="col-3 row">
      <div class="col-12 d-flex justify-content-center my-2">
        <button class="btn btn-sm btn-outline-secondary btn-block" @click="open">
          Cambiar contraseña
          <font-awesome-icon icon="key" class="pl-1 estilo-icono" />
        </button>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-sm btn-outline-secondary btn-block" @click="logOut" v-if="bye">
          Cerrar Sesión
          <font-awesome-icon icon="sign-out-alt" class="pl-1 estilo-icono" />
        </button>
        <button class="btn btn-sm btn-outline-secondary btn-block" type="button" disabled v-else>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Cerrando sesión...
        </button>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="updatePass">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cambiar contraseña</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label
                for="exampleInputPassword1"
                :class="errorPassword ? 'text-danger' : ''"
              >Contraseña nueva</label>
              <input
                type="password"
                class="form-control"
                :class="errorPassword ? 'border-danger' : ''"
                id="exampleInputPassword1"
                placeholder="*******"
                v-model="password"
              />
              <div class="invalid-feedback d-block" v-if="errorPassword">{{errorPasswordMessage}}</div>
            </div>
            <div class="form-group">
              <label
                for="exampleInputPassword2"
                :class="errorRepitPassword ? 'text-danger' : ''"
              >Repetir contraseña</label>
              <input
                type="password"
                class="form-control"
                :class="errorRepitPassword ? 'border-danger' : ''"
                id="exampleInputPassword2"
                placeholder="********"
                v-model="repitpassword"
              />
              <div
                class="invalid-feedback d-block"
                v-if="errorRepitPassword"
              >{{errorRepitPasswordMessage}}</div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="cambiar"
              v-if="estado"
            >Cambiar</button>
            <button class="btn btn-primary btn-sm" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Cambiando...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "configuracion",
  data() {
    return {
      bye: true,
      password: "",
      repitpassword: "",
      errorPassword: false,
      errorRepitPassword: false,
      errorRepitPasswordMessage: "",
      errorPasswordMessage: "",
      estado: true
    };
  },
  watch: {
    password() {
      if (this.password != "") {
        this.errorPassword = false;
        this.errorPasswordMessage = "";
      }
    },
    repitpassword() {
      if (this.repitpassword != "") {
        this.errorRepitPassword = false;
        this.errorRepitPasswordMessage = "";
      }
    }
  },
  methods: {
    logOut() {
      this.bye = false;
      this.$store.dispatch("logout").then(response => {
        if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
          this.$store.commit("setPosicion", "taller");
          localStorage.removeItem("usuario");
          this.$router.push("/");
          this.bye = true;
        } else {
          this.bye = true;
        }
      });
    },
    open() {
      $("#updatePass").modal("show");
    },
    close() {
      $("#updatePass").modal("hide");
      this.limpiar();
    },
    cambiar() {
      if (this.validar()) {
        this.estado = false;
        this.$store
          .dispatch("updatePassUser", { password: this.password })
          .then(response => {
            if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
              this.estado = true;
              this.close();
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: toast => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });

              Toast.fire({
                icon: "success",
                title: "Se cambio tu contraseña exitosamente."
              });
            } else {
              this.estado = true;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: toast => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });

              Toast.fire({
                icon: "success",
                title: "Error al cambiar la contraseña."
              });
            }
          });
      }
    },
    validar() {
      let contador = 0;
      if (this.password == "") {
        contador++;
        this.errorPassword = true;
        this.errorPasswordMessage = "Ingrese una contraseña.";
      }
      if (this.repitpassword == "") {
        contador++;
        this.errorRepitPassword = true;
        this.errorRepitPasswordMessage = "Ingrese una contraseña.";
      }
      if (this.password != "" && this.password != this.repitpassword) {
        contador++;
        this.errorPassword = true;
        this.errorPasswordMessage = "La contraseña no coinciden.";
      }
      if (this.repitpassword != "" && this.repitpassword != this.password) {
        contador++;
        this.errorRepitPassword = true;
        this.errorRepitPasswordMessage = "La contraseña no coinciden.";
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    limpiar() {
      this.errorPassword = false;
      this.errorPasswordMessage = "";
      this.errorRepitPassword = false;
      this.errorRepitPasswordMessage = "";
      this.password = "";
      this.repitpassword = "";
      this.estado = true;
    }
  }
};
</script>
<style lang="css">
.contenido-configuracion {
  width: 100%;
  height: calc(100vh - 113px);
}
</style>